<template>
  <div class="home">
    <!-- Repair No
    Product
    Repair Reasons -->
    <span style="color:#FF4D4D;"><b>*{{ $t("route.RedIsUnscannedProduct") }}</b></span> | <span style="color:#86D067;"><b>*{{ $t("route.GreenIsScannedProduct") }}</b></span>

    <a-list size="large" :pagination="false">
      <a-list-item
        :key="index"
        v-for="(item, index) in rma_tracking.data"
        :style="{backgroundColor: item.is_scanned === false ? '#FF4D4D' : '#86D067', padding: '10px 20px' }"
      >
        <a-row :gutter="24" style="width: 100%;">
          <a-col :sm="24" :md="12" :lg="12" :xl="3">
            <a-image width="75px" size="large" shape="square" :src="item.img"/>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="3">
            <span><b>{{ item.RmaID }}</b></span><br/>
            <span>{{ item.product_sku }}</span><br/>
            <span> {{ $t("route.Size") }} ({{ item.product_size }})</span><br/>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="4">
            <span><b>{{ $t("route.StoreReasons") }}</b></span>
            <div>
              <div v-for="reason in item.rma_item_to_reason" :key="reason.ID">
                <!-- <div v-if="reason.rma_item_reason_id == 13">
                  <div>{{ $t("reasonsName."+covertToCamelCase(reason.Name)) }}</div>
                  <div v-for="item in reason.RenewRmaReason" :key="item.ID">{{ $t("reasonsName.Renew") }}: {{ $t("reasonsName."+covertToCamelCase(item.Name)) }}</div>
                </div>
                <div v-else>{{ $t("reasonsName."+covertToCamelCase(reason.Name)) }}</div> -->
                {{ $t("reasonsName."+covertToCamelCase(reason.Name)) }}
              </div>
            </div>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="4" v-if="toReceivedOrToShip && item.is_scanned">
            <span><b>{{ $t("route.FactoryReasons") }}</b></span>
            <a-select
              v-model:value="item.FactoryReasons"
              mode="multiple"
              style="width: 100%"
              @change="handleChange(item)"
              :disabled="item.is_saved"
            >
              <a-select-option v-for="v in item.reason_list" :value="v.ID" :key="v.Name">{{ $t('reasonsName.' + covertToCamelCase(v.Name)) }}</a-select-option>
            </a-select>
            <div>
              <div class="sub-reason-title">{{ $t("reasonManage.subReason") }}</div>
              <a-select
                v-model:value="item.FactorySubReasons"
                mode="multiple"
                show-search
                style="width: 100%"
                :disabled="item.is_saved"
                :filterOption="filterOption"
              >
                <a-select-option v-for="v in item.subReasonList" :value="v.id" :key="v.name">{{ $t('reasonsName.' + covertToCamelCase(v.name)) }}</a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="4" v-if="!toReceivedOrToShip">
            <span><b>{{ $t("route.FactoryReasons") }}</b></span>
            <ul style="list-style-type:none;">
              <li v-for="reason in item.rma_factory_sav_reason" :key="reason.ID">{{ $t('reasonsName.' + covertToCamelCase(reason.Name)) }}</li>
            </ul>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="3" v-if="toReceivedOrToShip && item.is_scanned">
            <span><b>Original Produce Factory</b></span><br/>
            <a-select
              v-model:value="item.OriginalProduceFactory"
              style="width: 100%"
              :disabled="item.is_saved"
            >
              <a-select-option value="SAV">SAV</a-select-option>
              <a-select-option value="A01">A01</a-select-option>
              <a-select-option value="A05">A05</a-select-option>
              <a-select-option value="A07">A07</a-select-option>
              <a-select-option value="Subcontractors">Subcontractors</a-select-option>
              <a-select-option value="Others">Others</a-select-option>
            </a-select>
            <div style="height: 16px;"></div>
            <a-checkbox v-model:checked="item.isQualityIssue">{{ $t("route.QualityIssue") }}</a-checkbox>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="3" v-if="toReceivedOrToShip && item.is_scanned">
            <span><b>{{ $t('QualityInvestigate.QualityInvestigate') }}</b></span><br/>
            <a-select
              v-model:value="item.QualityInvestigate"
              style="width: 100%"
              :disabled="item.is_saved || item.QualityInvestigateDisabled"
            >
              <a-select-option v-for="options in item.QualityInvestigateOptions" :key="options.value" :value="options.value">{{ $t('QualityInvestigate.' + covertToCamelCase(options.label)) }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="3" v-if="toReceivedOrToShip">
            <span><b>{{ $t("route.WaivedFee") }}</b></span><br/>
            <span>{{ $t(`listRMA.waivedFeeStatus_${item.waived_fee_status}`) }}</span><br/>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="4" v-if="operationId == 13 && item.rma_gold_goods_history.id > 0">
            <span><b>{{ $t("productDetail.totalWeight") }} {{ $t("productDetail.Grams") }}</b></span><br/>
            <a-input v-model:value="item.gold_weight_after_repaired" />
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="4" v-if="operationId == 13 && item.rma_gold_goods_history.id > 0">
            <span><b></b></span><br/>
            <input
              type="file"
              id="upload_hidden_files"
              shape="round"
              @change="uploadGoldWeightFile($event, item)"
              class="custom-file-input"
              multiple
              accept="image/png, image/jpeg"
              :data-content="chooseFileName(item)"
            >
          </a-col>
        </a-row>
        <template #actions>
          <a-button v-if="item.is_scanned" type="primary">{{ $t("route.scanned") }} </a-button>
          <a-button v-else danger>{{ $t("route.unscanned") }} </a-button>
        </template>
      </a-list-item>
    </a-list>
    <!-- <span>{{ rma_tracking }}</span> -->
  </div>
</template>
<script>
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import useTrackingRmaRepositories from "@/composables/warehouse/useTrackingRmaRepositories"
  import { useI18n } from 'vue-i18n';
  import { onMounted, watch } from '@vue/runtime-core';
  import { compressImage } from '@/utils/utils'
  // import imageCompression from 'browser-image-compression';

  const data = []
  export default {
    data() {
      return {
        data
      }
    },
    setup() {
      const { rma_tracking, rma_trackingNumber, getRmaReasons, rma_reasons, getSubReasonsList } = useRmaRepositories()
      const { trackingNumber, latestProductScan, pendingProductScanned, addingProductScanned, toReceivedOrToShip, operationId } = useTrackingRmaRepositories()
      const { t ,locale } = useI18n({ useScope: 'global' })

      onMounted(() => {
        getRmaReasons()
      })

      const covertToCamelCase = (str) => {
        return str.replace(/\s/g, "")
      }

      const handleChange = async (item) => {
        item.subReasonList = []
        item.FactorySubReasons = []
        if (item.FactoryReasons.length > 0) {
          for (let i = 0; i < item.FactoryReasons.length; i++) {
            const result = await getSubReasonsList(item.FactoryReasons[i])
            if (result.success) item.subReasonList = item.subReasonList.concat(result.data.filter(v => v.is_active))
          }
        }
      }

      // watch(rma_tracking, (count, prevCount) => {
      //   if (count.data) {
      //     count.data.forEach(v => {
      //       v.reason_list = rma_reasons.value.data.filter(item => {
      //         return (item.ID != 16 && v.FactoryReasons.indexOf(12) == -1 && v.FactoryReasons.indexOf(16) == -1) || (item.ID != 16 && v.FactoryReasons.indexOf(12) > -1) || (item.ID != 12 && v.FactoryReasons.indexOf(16) > -1)
      //       })
      //     })
      //   }
      // })

      const chooseFileName = (record) => {
        return record.uploadGoldWeightImage && record.uploadGoldWeightImage.length > 0 ? t('route.Uploaded') + ` ${record.uploadGoldWeightImage.length} ` + t('route.images') : t('route.UploadTotalWeight')
      }

      const uploadGoldWeightFile = async (event, record) => {
        for(var i =0; i < event.target.files.length; i++) {
          const compressedImage = await compressImage(event.target.files[i])
          record.uploadGoldWeightImage.unshift(compressedImage)
        }

        // async function compressImage(img) {
        //   const options = {
        //     maxSizeMB: .5,
        //     maxWidthOrHeight: 1920,
        //     useWebWorker: true
        //   }
        //   const compressedimg = await imageCompression(img, options);
        //   return compressedimg
        // }
      }

      
      const filterOption = (input, option) => {
        option.search_text = t('reasonsName.' + covertToCamelCase(option.key))
        return option.search_text.toLowerCase().includes(input.toLowerCase());
      };

      return { rma_reasons, rma_tracking, rma_trackingNumber, trackingNumber, latestProductScan, pendingProductScanned, addingProductScanned, toReceivedOrToShip, locale, t, covertToCamelCase, handleChange, chooseFileName, uploadGoldWeightFile, operationId, filterOption }
    },
  }
</script>
<style lang="scss" scoped>
.ant-list-lg .ant-list-item {
  padding-top: 0px;
  padding-bottom: 0px;
}
.custom-file-input {
  color: transparent;
  border-radius: 32px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: attr(data-content);
  color: white;
  display: inline-block;
  background: -webkit-linear-gradient(top, #001628, #001628);
  border-radius: 32px;
  padding: 4px 16px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  // height: 22px;
}
/*
.zh .custom-file-input::before {
  content: 'Choose files';
} */
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.sub-reason-title {
  padding-left: 10px;
  margin-top: 10px;
}
</style>